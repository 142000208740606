@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    ::-webkit-file-upload-button{
        @apply 
        bg-theme-primary 
        text-theme-white 
        hover:bg-theme-btnHover 
        active:bg-theme-clicked 
        py-1.5 px-2.5 
        text-sm 
        rounded 
        font-light
        drop-shadow-none
        border-0
        cursor-pointer
    }
}
