.bg-tradeblue {
  background-color: #0D76C4;
}

.text-grey {
  color: #91BBE2;
}

.text-white {
  color: #fff;
}

.left-nav {
  width: 205px;
}