.card {
    @apply rounded;
}

.card-shadow {
    @apply shadow-default;
}

.card-header {
    @apply border-b;
}

.card-footer {
    @apply border-t;
}

.card-body {}

.card-footer-img {
    object-fit: contain;
    width: 40px;
    height: auto;
    /* background-image: url(https://tradestars.app/img/in.e4ab7bd0.svg);
    background-repeat: no-repeat; */
    border-radius: 50% !important;
    /* position: relative; */
    display: inline-block;
}