@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .custom-group-button :nth-child(2){
        @apply 
            border-l-0 border-r-0
            rounded-none;
    }
    .custom-group-button :nth-child(1){
        @apply 
           rounded-r-none;
    }
    .custom-group-button :nth-child(3){
        @apply 
            rounded-l-none;
    }
}