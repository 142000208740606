@tailwind components;

@layer components {
    .arrow-down {
        transform: rotate(90deg);
    }

    .arrow-up {
        transform: rotate(270deg);
    }
}