@tailwind components;

@layer components {
  .react-stepper .stepper-indicator {
    @apply flex justify-between items-center relative;
    width:300px;
  }

  .react-stepper .stepper-indicator:before {
    @apply h-px w-full bg-black absolute
  }

  .react-stepper .stepper-indicator:before {
    content: '';

  }

  .react-stepper .stepper-indicator .stepper-inner {
    @apply flex items-center bg-white p-5 z-1
  }

  .react-stepper .stepper-indicator .stepper-inner .stepper-number {
    @apply w-8 h-8 flex justify-center items-center rounded-full
  }

  .react-stepper .stepper-indicator .stepper-inner .stepper-number {
    border: 1px solid transparent;
  }

  .react-stepper .stepper-indicator .stepper-inner .stepper-number.step-bg {
    @apply bg-theme-primary text-white border-white
  }

  .react-stepper .stepper-indicator .stepper-inner .stepper-number.step-outline {
    @apply bg-transparent border-pink-800 text-red-700
  }

  .react-stepper .stepper-indicator .stepper-inner .stepper-label {
    @apply pl-2 text-theme-success
  }

  .react-stepper .stepper-indicator .stepper-inner.active .stepper-number.step-bg {
    @apply bg-gray-400 text-white border-gray-400
  }

  .react-stepper .stepper-indicator .stepper-inner.active .stepper-number.step-outline {
    @apply bg-transparent border-yellow-500 text-yellow-500
  }

  .react-stepper .stepper-indicator .stepper-inner.active .stepper-label {
    @apply text-gray-900
  }

  .react-stepper .stepper-indicator .stepper-inner.error .stepper-number.step-bg {
    @apply bg-red-700 text-white border-red-700
  }

  /* uncomment the code for future use */


  /* .react-stepper
    .stepper-indicator
    .stepper-inner.error
    .stepper-number.step-outline {
    background-color: var(--step-outline-error-bg);
    border-color: var(--step-outline-error-border);
    color: var(--step-outline-error-color);
  } */
  /* 
  .react-stepper .stepper-indicator .stepper-inner.error .stepper-label {
    color: var(--step-error-text-color);
  } */

  .react-stepper .stepper-data {
    @apply border rounded p-5
  }

  /* vertical stepper */

  .stepper {
    @apply flex justify-between items-center relative
  }

  .stepper:before {
    @apply h-px w-full bg-black absolute;
    width: 50%;
  }

  .stepper:before {
    content: '';
  }

  .stepper .step {
    @apply flex items-center p-5 z-1 bg-white
  }

  .stepper .step .step-counter {
    @apply w-5 h-5 flex justify-center items-center rounded-full border-transparent
  }

  .stepper .step .step-counter.step-bg {
    @apply bg-red-700 text-white border-red-700
  }

  .stepper .step .step-counter.step-outline {
    @apply bg-gray-400 text-white border-gray-400
  }

  .stepper .step .step-text {
    @apply pl-2 text-blue-600
  }

  .stepper .step.active .step-counter.step-bg {
    @apply bg-blue-500 text-white border-blue-500
  }

  .stepper .step.active .step-counter.step-outline {
    @apply bg-transparent border-blue-500 text-blue-500
  }

  .stepper .step.active .step-text {
    @apply text-blue-500
  }

  .stepper .step.error .step-counter.step-bg {
    @apply bg-red-700 text-white border-red-700
  }

  .stepper .step.error .step-counter.step-outline {
    @apply bg-red-700 border-red-700 text-red-700
  }

  .stepper .step.error .step-text {
    @apply text-red-700
  }

  .stepper.stepper-vertical {
    @apply flex-col items-start
  }

  .stepper.stepper-vertical:before {
    @apply h-full w-px left-38
  }
}