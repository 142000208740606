@layer components {
    .form-control, .form-control-medium{
        @apply 
            text-sm
            mb-4
            leading-18
            font-normal 
            border-solid 
            border 
            border-theme-neutralLighter40 
            hover:border-theme-neutralLighter40 
            focus:border-theme-neutralLighter40 
            hover:shadow-input 
            placeholder-theme-neutralLighter40 
            focus:shadow-input
            w-full
            text-theme-neutralLighter70
            pb-2.5 pt-2.5 px-4 rounded;
    }

    .form-control:focus-visible{
       @apply outline-default;
    }

    label{
        @apply text-xs mb-1 text-theme-neutralDarker70;
    }

    .form-control:disabled{
        @apply bg-theme-neutralLighter20 text-theme-neutralLighter60 border-opacity-0;
        background-color: #eaeae5;
    }
    .form-control:disabled:hover{
        box-shadow: none;
    }

    .loading-input, .loading-input:disabled{
        @apply bg-theme-neutralLighter20 text-opacity-0 border-opacity-0 hover:border-opacity-0 focus:border-opacity-0;
        
    }

    .feedback-icon{
        /* position: absolute; */
        @apply absolute right-2 top-3.5 text-theme-neutralLighter40; 
    }

    .form-control.loading{
        @apply bg-theme-disabled
    }

    .form-group .has-error{
        @apply border-theme-danger text-theme-danger
    }
    
    .form-group .has-success{
        @apply border-theme-success text-theme-success
    }
}