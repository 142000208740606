.table{
    border-spacing: 0;
}

.table th{
    text-align: inherit;
}

.table tr:last-child td {
    border-bottom: 0;
}

.table th:last-child, .table td:last-child {
    border-right: 0;
}

.pagination .form-group{
    margin-bottom: 0 !important;
}

.pagination .form-control{
    border-radius: 0;
}